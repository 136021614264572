import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const NavMenu = ({ menuWhiteClass, sidebarMenu }) => {
  const { t } = useTranslation();
  
  return (
    <div
      className={clsx(sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`)}
    >
      <nav>
        <ul>
          <li>
            <Link to={process.env.PUBLIC_URL + "/"}>
              {t("HOME")}
             </Link>
             </li>
            
              
             <li>
           <Link to={process.env.PUBLIC_URL + "/about"}>
                {" "}
                  {t("ABOUT US")}
                </Link>
              </li>
             
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/crystals"}>
                      {t("CRYSTALS")}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/ebook"}>
                      {t("E-BOOK")}
                    </Link>
                  </li>
          <li>
          <Link to={process.env.PUBLIC_URL + "/contact"}>
              {t("CONTACT US")}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
};

export default NavMenu;
