import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";

const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass }) => {
  return (
    <div className={clsx("copyright", spaceBottomClass, colorClass)}>
      <div className="footer-logo">
        <Link to={process.env.PUBLIC_URL + "/"}>
        <img
                src={process.env.PUBLIC_URL + "/assets/img/logo/soul.png"}
                alt=""className="logo mb-30"
              />
        </Link>
      </div>
      <p className="fot">
      Experience astrology like never before! At Soul Medic, I will provide you accurate readings, & personalized guidance; leaving you feeling empowered and inspired. 
      </p>
    </div>
  );
};

FooterCopyright.propTypes = {
  footerLogo: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string
};

export default FooterCopyright;
