import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FooterCopyright from "../../components/footer/FooterCopyright";
const FooterOne = ({
backgroundColorClass,
spaceTopClass,
spaceBottomClass,
spaceLeftClass,
spaceRightClass,
containerClass,
extraFooterClass,
sideMenu
}) => {
  const { t } = useTranslation();
return (
<footer className={clsx("footer-area", backgroundColorClass, spaceTopClass, spaceBottomClass, extraFooterClass, spaceLeftClass, spaceRightClass )}>
<div className={`${containerClass ? containerClass : "container"}`}>
<div className="row">
   <div
   className={`${
   sideMenu ? "col-xxl-4" : "col-xxl-4 col-lg-6 col-md-6 col-12"
   }`}
   >
   {/* footer copyright */}
   <FooterCopyright
      spaceBottomClass="mb-30"
      />
</div>
<div
className={`${
sideMenu ? "col-xxl-4" : "col-xxl-4 col-md-6 col-lg-6"
}`}
>
<div className="footer-widget mb-50 ml-30">
   <div className="footer-title">
      <h4>PHONE</h4>
      <div className="tel">  < a href="tel:+91 99998 98562">+91 99998 98562</a></div>
   </div>
   {/* footer newsletter */}
   <form
      className="wow fadeInUp"
      action="newsletter.php"
      method="post"
      encType="multipart/form-data"
      id="myForm"
      >
      <input
         className="bnnrfrm"
         name="email"
         type="email"
         placeholder="Enter Your Email Address"
         required=""
         />
      <button type="submit" id="news">SUBMIT</button>
   </form>
</div>
</div>
<div
className={`${
sideMenu ? " col-xxl-4" : "col-xxl-4 col-lg-6 col-md-6 col-12"
}`}
>
<div
   >
   <div className="footer-title2">
      <h4>EMAIL ID</h4>
      <div className="tel">  <a href="mailto: soulmedicc@gmail.com"> soulmedicc@gmail.com</a></div>
   </div>
   <div className="footer-list">
   </div>
</div>
</div>
</div>
</div>
<>
<div className="container-fluid"id="copyright-text">
   <div className="row">
      <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
         <p  className="fotlast1">Copyright 2023 Created with ❤️ by Dashmeet Kaur</p>
      </div>
      <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
        <nav class="navbar navbar-expand-lg " >
          <div >

              <ul class="navbar-nav">
                <li class="nav-item">
                    <Link to={process.env.PUBLIC_URL + "/terms-conditions"}>
                      {t("Terms & Conditions")}
                    </Link>
                </li>
                <li class="nav-item">
                  <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
                      {t("Privacy Policy")}
                    </Link>
                </li>
                <li class="nav-item">
                    <Link to={process.env.PUBLIC_URL + "/cancelation-refund"}>
                      {t("Cancellation")}
                    </Link>
                </li>
              </ul>
        
          </div>
        </nav>
      </div>
      <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
         <div className="contact-social text-center">
            <ul>
               <li>
                  <a href="//facebook.com">
                  <i className="fa fa-facebook" id="faicon" />
                  </a>
               </li>
               <li>
                  <a href="https://www.instagram.com/getthehealingsdone/">
                  <i class="fa fa-instagram" id="faicon"/>
                  </a>
               </li>
               <li>
                  <a href="//twitter.com">
                  <i className="fa fa-twitter" id="faicon" />
                  </a>
               </li>
            </ul>
         </div>
      </div>
   </div>
</div>
</>
</footer>
);
};
FooterOne.propTypes = {
backgroundColorClass: PropTypes.string,
containerClass: PropTypes.string,
extraFooterClass: PropTypes.string,
sideMenu: PropTypes.bool,
spaceBottomClass: PropTypes.string,
spaceTopClass: PropTypes.string,
spaceLeftClass: PropTypes.string,
spaceRightClass: PropTypes.string
};
export default FooterOne;