import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MobileNavMenu = () => {
  const { t } = useTranslation();

  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/"}>{t("Home")}</Link>
          </li>
          <li>
           <Link to={process.env.PUBLIC_URL + "/about"}>
                {" "}
                  {t("ABOUT US")}
                </Link>
              </li>
             
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/crystals"}>
                      {t("CRYSTALS")}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/ebook"}>
                      {t("E-BOOK")}
                    </Link>
                  </li>
          <li>
          <Link to={process.env.PUBLIC_URL + "/contact"}>
              {t("CONTACT US")}
            </Link>
          </li>
        </ul>
          
    </nav>
  );
};

export default MobileNavMenu;
